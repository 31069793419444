import { useI18n } from 'vue-i18n'
import { MfiAPI } from '@hubelia/mfiapi'
import { isAxiosError } from 'axios'
import { isString } from 'lodash-es'

export function useBackendSDK(params?: { disableGenericError?: boolean }) {
  const { disableGenericError = false } = params ?? {}
  const config = useRuntimeConfig()
  const { t } = useI18n()
  const { t: $t } = useI18n({ useScope: 'global' })
  const toast = useToast()
  const { data, signOut } = useAuth()
  const { status } = useAuthState()
  const beUrl = config.public.backendUrl

  if (!beUrl)
    throw new Error(
      'Backend URL not found. Make sure to define the BACKEND_URL environment variable.'
    )

  const sdk = new MfiAPI({
    baseUrl: beUrl,
    token: (data.value as typeof data.value & { accessToken?: string })?.accessToken
  })

  sdk.axios.interceptors.request.use(
    function (config) {
      const accessToken = (data.value as { accessToken?: string })?.accessToken

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : ''
        }
      }
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  sdk.axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (isAxiosError(err)) {
        const errorStatus = err.response?.status ?? 500
        const isAddMoviesWarn = isString(err?.response?.data)
          ? err?.response?.data?.includes($t('shared.alreadyInList'))
          : false
        if (errorStatus === 401 && status.value === 'authenticated') {
          toast.add({
            severity: 'error',
            summary: t('error.sessionExpired.title'),
            detail: t('error.sessionExpired.message'),
            group: 'custom-toast',
            life: 5000
          })
          await signOut()
        } else if (
          errorStatus >= 400 &&
          errorStatus < 500 &&
          typeof err.response?.data === 'string'
        ) {
          toast.add({
            severity: isAddMoviesWarn ? 'warn' : 'error',
            summary: isAddMoviesWarn ? $t('shared.warnSummary') : t('error.generic.title'),
            detail: err.response.data,
            group: 'custom-toast',
            life: 5000
          })
        } else if (!disableGenericError) {
          toast.add({
            severity: 'error',
            summary: t('error.generic.title'),
            detail: t('error.generic.message'),
            group: 'custom-toast',
            life: 5000
          })
        }
      }

      return Promise.reject(err)
    }
  )

  return { sdk }
}
