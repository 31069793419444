import type { MaybeRefOrGetter } from 'vue'

export function useResponsive() {
  const { width, height } = useWindowSize()
  const { isMobileOrTablet } = useDevice()

  const hasSkin = useState('hasSkin', () => false)

  const isDesktop = computed(() => {
    if (import.meta.server) {
      return !isMobileOrTablet
    } else {
      return width.value >= 1024 && !hasSkin.value
    }
  })

  const isSkinDesktop = computed(() => {
    return width.value >= 1023
  })
  return {
    isDesktop,
    isMobile: computed(() => !isDesktop.value),
    isSkinDesktop,
    width,
    height
  }
}

export function useGridColumns(el: MaybeRefOrGetter<HTMLElement | null>) {
  const { width } = useResponsive()
  const throttledWidth = useThrottle(width, 250)
  return computedWithControl([throttledWidth, el], () => {
    const grid = unref(el)
    if (!grid) return Infinity
    else {
      return getComputedStyle(grid as HTMLElement).gridTemplateColumns.split(' ').length
    }
  })
}
